<template>
  <div>
    <CRow>
      <CCol md="12">
        <h1 style="font-size: 1.5rem">{{user_name}} (Ruta: {{route_name}})</h1>
        <CButton color="info" size="sm" style="margin: auto;" @click="$router.push({path:'/welcome'})">Volver</CButton><br />
        <br />
      </CCol>
    </CRow>
    <CRow>
      <CCol md="8">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CIcon name="cil-star"/> Clientes (TOP)
              <div class="card-header-actions"></div>
            </slot>
          </CCardHeader>
          <CCardBody>
            <TopCustomersTable
              :items="top_customers"
              hover
              border
              small
              fixed
              caption="Clientes TOP"
            />
            <CCardText></CCardText>
          </CCardBody>
        </CCard>

        <CCard>
          <CCardHeader>
            <slot name="header">
              <CIcon name="cil-star"/> Clientes (DETALLE)
              <div class="card-header-actions"></div>
            </slot>
          </CCardHeader>
          <CCardBody>
            <DetalleCustomersTable
              :items="detalle_customers"
              hover
              border
              small
              fixed
              caption="Clientes DETALLE"
            />
            <CCardText></CCardText>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol md="4">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <CIcon name="cil-star"/> Beneficios Obtenidos
              <div class="card-header-actions"></div>
            </slot>
          </CCardHeader>
          <CCardBody>
            <ProfitCustomersTable
              :items="profits_customers"
              hover
              border
              small
              fixed
              caption="Clientes DETALLE"
            />
            <CCardText>
            <CCard>
              <CCardHeader>
                <slot name="header">
                  <b>Total de Beneficios</b>
                  <div class="card-header-actions"></div>
                </slot>
              </CCardHeader>
              <CCardBody>
                <h1 style="text-align: center;">$ {{total | currency}}</h1>
              </CCardBody>
            </CCard>
          </CCardText>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>

import store from '../../../store'
import challenges_ws from '../../../services/challenges';
import account_ws from '../../../services/account';
import customer_ws from '../../../services/customers';

import TopCustomersTable from '../../../components/dashboard/TopCustomersTable.vue'
import DetalleCustomersTable from '../../../components/dashboard/DetalleCustomersTable.vue'
import ProfitCustomersTable from '../../../components/dashboard/ProfitCustomersTable.vue'

export default {
  name: 'SuppliersHome',
  components: {
    TopCustomersTable,
    DetalleCustomersTable,
    ProfitCustomersTable
  },
  data () {
    return {
      name: '',
      user_name: '',
      route_name: '',
      top_customers: [],
      detalle_customers: [],
      profits_customers: [],
      total: 0,
      points: 0,
      challenges: false
    }
  },
  mounted: async function() {
    let response = await account_ws.getInfo(); 

    if(response.type == "success"){
      this.name = response.data.name;
      this.route_name = response.data.user.first_name;
      this.user_name = response.data.user.last_name;
    }

    let response2 = await customer_ws.getBySupplier(response.data.user.id); 

    if(response2.type == "success"){
      this.top_customers = response2.data.top;
      this.detalle_customers = response2.data.detalle;
      this.profits_customers = response2.data.profits;
      this.total = response2.data.total;
    }

    let response3 = await account_ws.getPoints(); 

    if(response3.type == "success"){
      this.points = response3.data;
    }

    let response4 = await challenges_ws.getActives(); 

    if(response4.type == "success"){
      this.challenges = response4.data;
    }
  },
  methods: {
    color (value) {
      let $color
      if (value <= 25) {
        $color = 'info'
      } else if (value > 25 && value <= 50) {
        $color = 'success'
      } else if (value > 50 && value <= 75) {
        $color = 'warning'
      } else if (value > 75 && value <= 100) {
        $color = 'danger'
      }
      return $color
    },
    async refreshTopCustomers (top_customers){
      this.loading();
      this.top_customers = await top_customers;
      this.loaded();
    },
  }
}
</script>
